import { Button, Input, Select } from '@evidation/ui';
import { CloseCircle, PlusCircle } from '@evidation/ui/lib/icons';
import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import stripHtml from 'src/utils/stripHtml';
import {
  TileQuestion,
  findTileQuestion,
  getDefaultOperatorFromInterface,
  getDefaultTargetTypeFromInterface,
  useQuestionsInTileSequence,
} from '../utils';
import RHFSelectWrapper from 'src/components/inputs/select-rhf-wrapper';
import _ from 'lodash';
import { Tile } from 'src/redux/graph';

import styled from 'styled-components';

const StyledLogicQuestion = styled.div`
  padding: 1rem;
  background-color: #e7e7e7;
  border-radius: 6px;
  flex: 1 1 100%;
  position: relative;

  // horizontal line for question boxes
  &::before {
    content: '';
    position: absolute;
    display: block;
    top: 50%;
    left: 0;
    transform: translateX(-28px);
    z-index: -1;
    height: 1px;
    width: 20px;
    border-top: 2px dashed #e7e7e7;
  }
`;

const StyledLogicItem = styled.li`
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
`;

export type LogicItemProps = {
  field: any;
  id: number;
  append: any;
  remove: any;
  prefix: string;
  targetQuestion: TileQuestion;
  tile: Tile;
  type: string;
};

export const SINGLE_VALUE_OPERATORS = [
  { label: 'greater than', value: '>' },
  { label: 'greater than or equal to', value: '>=' },
  { label: 'less than', value: '<' },
  { label: 'less than or equal to', value: '<=' },
];

export const BASE_OPERATORS = [
  { label: 'is equal to', value: '==' },
  { label: 'is not equal to', value: '!=' },
  { label: 'present', value: 'present' },
];

export const ANSWER_VALUE_OPERATORS = [
  ...BASE_OPERATORS,
  ...SINGLE_VALUE_OPERATORS,
];

export const ANSWER_CHOICE_OPERATORS = [
  ...BASE_OPERATORS,
  { label: 'is at least one of the following', value: 'in' },
  { label: 'contains', value: 'includes' },
];

export const OPERATORS_OPTIONS = [
  { label: 'contains', value: 'includes' },
  { label: 'present', value: 'present' },
  { label: 'is at least one of the following', value: 'in' },
  { label: 'is equal to', value: '==' },
  { label: 'is not equal to', value: '!=' },
  { label: 'greater than', value: '>' },
  { label: 'greater than or equal to', value: '>=' },
  { label: 'less than', value: '<' },
  { label: 'less than or equal to', value: '<=' },
];

export default function LogicItem({
  field,
  id,
  append,
  remove,
  prefix,
  targetQuestion,
  tile,
  type,
}: LogicItemProps) {
  const {
    watch,
    setValue,
    control,
    register,
    formState: { errors },
  } = useFormContext();
  const targetQuestionPossibleAnswerOptions = [
    ...(targetQuestion.form.possible_answers ?? []),
    ...(targetQuestion.form.mutually_exclusive_answers ?? []),
  ].map((pa) => ({
    label: stripHtml(pa.label),
    value: pa.answer,
  }));

  const path = `${prefix}.conditions.${id}`;
  const localErrors = _.get(errors, path);
  const targetType = watch(`${path}.targetType`);
  const sourceTargetType = watch(`${path}.sourceTargetType`);
  const targetName = watch(`${path}.target_name`);
  const currentOperator = watch(`${path}.operator`);
  const v = watch(`${path}.value`);

  const allowMultipleTargetSelections = useMemo(
    () => currentOperator === 'in',
    [currentOperator],
  );

  useEffect(() => {
    if (allowMultipleTargetSelections && !Array.isArray(v)) {
      setValue(`${path}.value`, [], {
        shouldTouch: true,
        shouldDirty: true,
        shouldValidate: true,
      });
    }
  }, [v, allowMultipleTargetSelections, path, setValue]);

  const otherQuestions = useQuestionsInTileSequence(tile, {
    returnPreceding: true,
    includeCurrent: false,
    componentId: targetQuestion.form.component_id!,
  });
  const otherQuestionOptions = useMemo(() => {
    return otherQuestions.map(({ form: question, tileId }) => ({
      label: `${question.interface} -  ${stripHtml(question.label)}`,
      value: `${question.component_id}|${tileId}`,
    }));
  }, [otherQuestions]);

  const targetQuestionValue = otherQuestions.find(
    (question) => question.form.component_id === targetName,
  );

  const sourceQuestionOptions = useMemo(() => {
    return [
      ...(targetQuestionValue?.form.possible_answers ?? []),
      ...(targetQuestionValue?.form.mutually_exclusive_answers ?? []),
    ].map((pa) => ({
      label: stripHtml(pa.label),
      value: pa.answer,
    }));
  }, [
    targetQuestionValue?.form.possible_answers,
    targetQuestionValue?.form.mutually_exclusive_answers,
  ]);

  const sourceQuestion = useMemo(() => {
    return findTileQuestion(tile, targetName)?.form;
  }, [tile, targetName]);

  useEffect(() => {
    setValue(`${path}.target_interface`, sourceQuestion?.interface);
  }, [sourceQuestion, path, setValue]);

  let baseTargetTypeOptions = [
    { label: 'Question', value: 'question' },
    { label: 'User Characteristics', value: 'user-characteristic' },
  ];

  const defaultTargetValue = useMemo(() => {
    if (sourceQuestion?.interface) {
      const intf = sourceQuestion?.interface;
      return getDefaultTargetTypeFromInterface(intf);
    }
    if (targetQuestion?.form?.interface) {
      const intf = targetQuestion.form.interface;
      return getDefaultTargetTypeFromInterface(intf);
    }
  }, [targetQuestion, sourceQuestion]);

  const defaultOperatorValue = useMemo(() => {
    const intf = targetQuestion.form.interface;
    return getDefaultOperatorFromInterface(intf);
  }, [targetQuestion]);

  useEffect(() => {
    if (!sourceTargetType && defaultTargetValue && sourceQuestion) {
      setValue(`${path}.sourceTargetType`, defaultTargetValue, {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      });
    }
  }, [defaultTargetValue, sourceTargetType, sourceQuestion, path, setValue]);

  if (type === 'skip') {
    baseTargetTypeOptions.push({
      label: 'Answer value',
      value: 'answer_value',
    });
  }

  if (
    Array.isArray(targetQuestionPossibleAnswerOptions) &&
    targetQuestionPossibleAnswerOptions.length > 0 &&
    type === 'skip'
  ) {
    baseTargetTypeOptions.push({
      label: 'Answer choice',
      value: 'answer_choice',
    });
  }

  let baseSourceTargetTypeOptions = [
    { label: 'Answer value', value: 'answer_value' },
  ];

  if (
    Array.isArray(sourceQuestionOptions) &&
    sourceQuestionOptions.length > 0 &&
    targetType !== 'user-characteristic'
  ) {
    baseSourceTargetTypeOptions.push({
      label: 'Answer choice',
      value: 'answer_choice',
    });
  }
  const evaluateOperatorOptions = useMemo(() => {
    const target = sourceTargetType || targetType;
    const options =
      target === 'answer_choice'
        ? ANSWER_CHOICE_OPERATORS
        : ANSWER_VALUE_OPERATORS;
    if (
      (sourceQuestion && sourceQuestion.interface === 'checkbox') ||
      (sourceQuestion && sourceQuestion.interface === 'checkboxWithNone') ||
      (sourceQuestion &&
        sourceQuestion.interface === 'selectadvanced' &&
        sourceQuestion?.is_multi_select)
    ) {
      return [...options];
    } else if (
      (sourceQuestion &&
        sourceQuestion.interface === 'selectadvanced' &&
        !sourceQuestion?.is_multi_select) ||
      targetQuestion?.form?.interface === 'selectadvanced'
    ) {
      return [...options, ...SINGLE_VALUE_OPERATORS].filter(
        (v) => v.value !== 'includes',
      );
    } else if (
      (sourceQuestion && sourceQuestion.interface === 'number') ||
      (sourceQuestion && sourceQuestion.interface === 'radio')
    ) {
      return [...options, ...SINGLE_VALUE_OPERATORS]
        .filter((v) => v.value !== 'in')
        .filter((v) => v.value !== 'includes');
    }
    return options;
  }, [sourceTargetType, targetType, sourceQuestion, targetQuestion]);

  return (
    <StyledLogicItem>
      <StyledLogicQuestion>
        {id === 0 &&
          (targetType === 'answer_choice' || targetType === 'answer_value') && (
            <p>{stripHtml(targetQuestion.form.label)}</p>
          )}
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            marginBottom: 10,
          }}
        >
          <Select
            options={baseTargetTypeOptions}
            value={targetType}
            style={{ minWidth: 150, marginRight: 10 }}
            error={localErrors?.targetType?.message as string | undefined}
            onChange={(v) => {
              setValue(`${path}.targetType`, v.value);
              if (v.value === 'answer_choice') {
                setValue(`${path}.value`, [], {
                  shouldDirty: true,
                  shouldValidate: true,
                });
              } else if (v.value === 'user-characteristic') {
                setValue(`${path}.sourceTargetType`, 'answer_value');
                setValue(`${path}.value`, '', { shouldValidate: true });
              } else {
                setValue(`${path}.value`, '', {
                  shouldValidate: true,
                  shouldDirty: true,
                });
              }
            }}
          />
          {targetType !== 'question' &&
            targetType !== 'user-characteristic' && (
              <RHFSelectWrapper
                control={control}
                options={evaluateOperatorOptions}
                name={`${path}.operator`}
                label=""
                style={{ minWidth: 120, marginRight: 10 }}
              />
            )}
          {targetType === 'answer_choice' && currentOperator !== 'present' && (
            <Select
              options={targetQuestionPossibleAnswerOptions}
              multiple={allowMultipleTargetSelections}
              label=""
              value={watch(`${path}.value`)}
              error={localErrors?.value?.message as string | undefined}
              name={`${path}.value`}
              style={{ flex: '1 1 auto' }}
              onChange={(v: any) => {
                if (allowMultipleTargetSelections && Array.isArray(v)) {
                  setValue(
                    `${path}.value`,
                    v.map((v) => Number(v.value)),
                    {
                      shouldValidate: true,
                      shouldDirty: true,
                      shouldTouch: true,
                    },
                  );
                } else {
                  setValue(`${path}.value`, v.value, {
                    shouldValidate: true,
                    shouldDirty: true,
                    shouldTouch: true,
                  });
                }
              }}
            />
          )}
          {targetType === 'answer_value' && currentOperator !== 'present' && (
            <>
              <Input
                id={`id_${path}.value`}
                {...register(`${path}.value`)}
                flow
                labelHidden
                label="Value"
                error={localErrors?.value?.message as string | undefined}
              />
            </>
          )}
          {targetType === 'user-characteristic' && (
            <Input
              id={`id_${path}.user_characteristic_target`}
              {...register(`${path}.user_characteristic_target`)}
              flow
              labelHidden
              label="Value"
              error={
                localErrors?.user_characteristic_target?.message as
                  | string
                  | undefined
              }
            />
          )}
          {targetType === 'question' &&
            // Note: This IIFE syntax isn't ideal but allows us to define some
            //   intermediary variables to simplify the logic and avoid
            //   calling `watch` unnecessarily.
            (() => {
              const targetName = watch(`${path}.target_name`) as string;
              const tileId = watch(`${path}.targetTileId`) as string;
              return (
                <Select
                  options={otherQuestionOptions}
                  value={targetName && tileId ? `${targetName}|${tileId}` : ''}
                  style={{ flex: '1 1 auto' }}
                  onChange={(v) => {
                    const [componentId, tileId] = v.value.split('|');
                    setValue(`${path}.sourceTargetType`, '', {
                      shouldValidate: true,
                      shouldDirty: true,
                      shouldTouch: true,
                    });
                    setValue(`${path}.value`, '', {
                      shouldValidate: true,
                      shouldDirty: true,
                      shouldTouch: true,
                    });
                    setValue(`${path}.target_name`, componentId, {
                      shouldValidate: true,
                      shouldDirty: true,
                      shouldTouch: true,
                    });
                    if (tileId) {
                      setValue(`${path}.targetTileId`, tileId, {
                        shouldValidate: true,
                        shouldDirty: true,
                        shouldTouch: true,
                      });
                    }
                  }}
                />
              );
            })()}
        </div>
        {(targetType === 'question' ||
          targetType === 'user-characteristic') && (
          <div style={{ display: 'flex', alignItems: 'flex-start' }}>
            <Select
              options={baseSourceTargetTypeOptions}
              value={watch(`${path}.sourceTargetType`)}
              error={
                localErrors?.sourceTargetType?.message as string | undefined
              }
              style={{ minWidth: 150, marginRight: 10 }}
              onChange={(v) => {
                setValue(`${path}.sourceTargetType`, v.value, {
                  shouldValidate: true,
                  shouldDirty: true,
                });
                if (v.value === 'answer_choice') {
                  setValue(`${path}.value`, [], {
                    shouldValidate: true,
                    shouldDirty: true,
                  });
                } else {
                  setValue(`${path}.value`, '', {
                    shouldValidate: true,
                    shouldDirty: true,
                  });
                }
              }}
            />
            <RHFSelectWrapper
              control={control}
              options={evaluateOperatorOptions}
              name={`${path}.operator`}
              style={{ minWidth: 120, marginRight: 10 }}
            />
            {sourceTargetType === 'answer_choice' &&
              currentOperator !== 'present' && (
                <Select
                  options={sourceQuestionOptions}
                  multiple={allowMultipleTargetSelections}
                  value={watch(`${path}.value`)}
                  error={localErrors?.value?.message as string | undefined}
                  name={`${path}.value`}
                  style={{ flex: '1 1 auto' }}
                  onChange={(v) => {
                    if (allowMultipleTargetSelections) {
                      setValue(
                        `${path}.value`,
                        v.map((v) => v.value),
                        {
                          shouldDirty: true,
                          shouldValidate: true,
                        },
                      );
                    } else {
                      setValue(`${path}.value`, v.value, {
                        shouldValidate: true,
                        shouldDirty: true,
                      });
                    }
                  }}
                />
              )}
            {sourceTargetType === 'answer_value' &&
              currentOperator !== 'present' && (
                <div style={{ width: '100%' }}>
                  <Input
                    inline
                    labelHidden
                    id={`id_${path}.value`}
                    {...register(`${path}.value`, {})}
                    error={localErrors?.value?.message as string | undefined}
                    flow
                  />
                </div>
              )}
          </div>
        )}
      </StyledLogicQuestion>
      <Button
        variant="ghost"
        type="button"
        aria-label="Add another row"
        onClick={() =>
          append(id + 1, {
            operator: defaultOperatorValue,
            targetType: type === 'display' ? 'question' : defaultTargetValue,
            value: '',
          })
        }
      >
        <PlusCircle />
      </Button>

      <Button
        variant="ghost"
        color="danger"
        type="button"
        aria-label="Remove row"
        onClick={() => remove(id)}
      >
        <CloseCircle />
      </Button>
    </StyledLogicItem>
  );
}
