import * as C from '../common';
import { default_experience_key } from '../../../../constants';
import _ from 'lodash';
import { alphaNumericUnderscore } from '../stripSpecialCharacters';

const testSequentialPrefixName = (path, values) =>
  _.get(values, path.slice(0, -1).join('.')).format ===
  'sequential_prefix_range';

export const AssignExternalIdTileSettings = (
  experience,
  masonryEnabled = false,
) => ({
  label: 'Settings',
  name: `content`,
  input: [
    C.experienceTileTitle(masonryEnabled),
    C.experienceTileDescription(masonryEnabled),
    ...C.dependencyCriteria({
      emitEvent: true,
      visibility: true,
      cancellation: true,
    }),
  ],
});

export default ({ experience = default_experience_key, dataForTile }) => {
  const {
    assign_external_id: { masonryEnabled = false },
  } = dataForTile;

  const experienceName = masonryEnabled
    ? `content.experiences.${experience}.settings`
    : `content`;

  const schema = [
    {
      label: 'Content',
      name: experienceName,
      input: [
        C.experienceExternalTitle(masonryEnabled),
        C.description,
        {
          title: 'External IDs',
          name: 'external_ids',
          interface: 'array',
          items: [
            {
              label: 'format',
              name: 'format',
              interface: 'select',
              required: true,
              options: ['random_hex', 'sequential_prefix_range', 'list'],
              initialValue: 'random_hex',
            },
            {
              label: 'Client Name',
              name: 'client',
              interface: 'textinput',
              parse: (value) => alphaNumericUnderscore(value),
              required: true,
            },
            {
              label: 'user_data key',
              name: 'user_data_key',
              interface: 'textinput',
              parse: (value) => alphaNumericUnderscore(value),
              required: true,
            },
            {
              label: 'prefix',
              name: 'prefix',
              interface: 'textinput',
              parse: (value) => alphaNumericUnderscore(value),
              condition: (path, { values }) =>
                testSequentialPrefixName(path, values),
            },
            {
              label: 'range start',
              name: 'range_start',
              interface: 'number',
              required: true,
              condition: (path, { values }) =>
                testSequentialPrefixName(path, values),
            },
            {
              label: 'range end',
              name: 'range_end',
              interface: 'number',
              required: true,
              condition: (path, { values }) =>
                testSequentialPrefixName(path, values),
            },
          ],
        },
      ],
    },
  ];

  if (!masonryEnabled) {
    schema.push(AssignExternalIdTileSettings(experience));
  }

  return schema;
};
