import { Button } from '@evidation/ui';
import { CloseCircle, PlusCircle } from '@evidation/ui/lib/icons';
import _ from 'lodash';
import { useFieldArray, useFormContext } from 'react-hook-form';
import RHFSelectWrapper from 'src/components/inputs/select-rhf-wrapper';
import LogicItem from './logic-item';
import { Fragment, useMemo } from 'react';
import {
  TileQuestion,
  getDefaultOperatorFromInterface,
  getDefaultTargetTypeFromInterface,
} from '../utils';
import { Tile } from 'src/redux/graph';
import styled from 'styled-components';

type Props = {
  name: string;
  insert: any;
  append: any;
  id: number;
  remove: any;
  targetQuestion: TileQuestion;
  tile: Tile;
  type: string;
  count: number;
};

const ActionButton = styled(Button)`
  margin: 0;
  padding: 0;
`;

const StyledQuestionsWrapper = styled.ul<{
  depth: number;
  fields: number;
  conditions: number;
  idx: number;
  operator: string;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  margin-left: ${(props) => `${60 * (props.depth + 1)}px`};

  li {
    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      min-height: ${(props) => {
        if (props.fields === 0) return '0';
        return 'calc(100% + 15px)';
      }};
      transform: translateX(-30px);
      width: 1px;
      border-left: ${(props) =>
        props.operator === 'or' ? '2px dashed #e7e7e7' : '2px solid #e7e7e7'};
    }

    &:last-of-type {
      &::before {
        min-height: 50%;
      }
    }
  }
`;

const StyledItemHeader = styled.p<{
  depth: number;
  fields: number;
  operator: string;
}>`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
  padding-left: ${(props) => `${60 * props.depth}px`};
  position: relative;

  &::before {
    content: '';
    position: absolute;
    display: ${(props) => (props.depth === 0 ? 'none' : 'block')};
    top: 50%;
    left: ${(props) => (props.depth > 1 ? '120px' : '60px')};
    transform: translateX(-26px);
    z-index: -1;
    height: 1px;
    width: 20px;
    border-top: ${(props) =>
      props.operator === 'or' ? '2px dashed #e7e7e7' : '2px solid #e7e7e7'};
  }
`;

const StyledAddItemButton = styled.div<{ operator: string }>`
  width: 120px;
  padding: 0;

  &::before {
    content: '';
    position: absolute;
    display: block;
    top: 50%;
    left: 0;
    transform: translateX(-30px);
    height: 1px;
    width: 25px;
    border-top: ${(props) =>
      props.operator === 'or' ? '2px dashed #e7e7e7' : '2px solid #e7e7e7'};
  }

  &::after {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    transform: translateX(-30px);
    z-index: -2;
    height: 50%;
    width: 1px;
    border-left: ${(props) =>
      props.operator === 'or' ? '2px dashed #e7e7e7' : '2px solid #e7e7e7'};
  }
`;

const StyledItemCondition = styled.article<{
  depth: number;
  fields: number;
  count: number;
  idx: number;
  operator: string;
}>`
  position: relative;
  padding-top: 16px;

  &:first-of-type {
    padding-top: 0;
    &::before {
      display: none;
    }
  }

  &::before {
    content: '';
    position: absolute;
    left: 30px;
    top: -60px;
    width: 1px;
    border-left: ${(props) =>
      props.operator === 'or' ? '2px dashed #e7e7e7' : '2px solid #e7e7e7'};
    display: ${(props) => {
      if (props.depth === 2 && props.count - 1 === props.idx) return 'none';
      return 'block';
    }};
    min-height: ${(props) => {
      if (
        props.depth === 1 &&
        props.fields > 0 &&
        props.count - 1 === props.idx
      )
        return '98px';
      return '100%';
    }};
    z-index: -1;
  }

  &::after {
    content: '';
    position: absolute;
    display: ${(props) => (props.depth > 1 ? 'block' : 'none')};
    top: -50px;
    left: 90px;
    width: 1px;
    min-height: ${(props) => {
      if (props.depth === 2 && props.count - 1 === props.idx) return '88px';
      if (props.depth === 2 && props.fields > 0) return 'calc(100% - 2px)';
      return '88px';
    }};
    z-index: -1;
    border-left: ${(props) =>
      props.operator === 'or' ? '2px dashed #e7e7e7' : '2px solid #e7e7e7'};
  }
`;

export default function ConditionItem({
  name,
  insert,
  append,
  id,
  remove,
  targetQuestion,
  tile,
  type,
  count,
}: Props) {
  const { watch, control } = useFormContext();
  const {
    fields,
    insert: insertSubField,
    remove: removeSubField,
  } = useFieldArray({
    control,
    name: `conditions.${id}.conditions`,
  });

  const items = watch(`conditions`);
  const item = items[id];
  const children = _.keys(_.pickBy(items, { parent: id }));

  const removeCondition = () => {
    if (id === 0) {
      return append({ parent: id, depth: item.depth + 1 });
    }
    if (children.length) {
      const indexOfLastChild = Math.max(...children.map((s) => Number(s)));

      return insert(indexOfLastChild + 1, {
        parent: id,
        depth: item.depth + 1,
      });
    }
    insert(id + 1, {
      parent: id,
      depth: item.depth + 1,
    });
  };

  const intf = targetQuestion?.form?.interface;

  const defaultTargetValue = useMemo(() => {
    return getDefaultTargetTypeFromInterface(intf);
  }, [intf]);

  const defaultOperatorValue = useMemo(() => {
    return getDefaultOperatorFromInterface(intf);
  }, [intf]);

  return (
    <StyledItemCondition
      depth={item.depth}
      fields={fields.length}
      count={count}
      idx={id}
      operator={item.operator}
    >
      <StyledItemHeader
        depth={item.depth}
        fields={fields.length}
        operator={item.operator}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          If
          <RHFSelectWrapper
            name={name}
            control={control}
            style={{ minWidth: 100 }}
            options={[
              { label: 'Any', value: 'or' },
              { label: 'All', value: 'and' },
            ]}
          />
          of the following are true
        </div>
        {id > 0 && (
          <ActionButton
            type="button"
            variant="ghost"
            onClick={() => remove([id, ...children])}
          >
            <CloseCircle style={{ color: 'red' }} />
          </ActionButton>
        )}
        {item.depth < 2 && (
          <ActionButton type="button" variant="ghost" onClick={removeCondition}>
            <PlusCircle />
          </ActionButton>
        )}
      </StyledItemHeader>
      <StyledQuestionsWrapper
        depth={item.depth}
        fields={fields.length}
        conditions={count}
        idx={id}
        operator={item.operator}
      >
        {fields.length ? (
          <>
            {fields.map((field, fieldIdx) => (
              <Fragment key={field.id}>
                <LogicItem
                  field={field}
                  append={insertSubField}
                  targetQuestion={targetQuestion}
                  tile={tile}
                  remove={removeSubField}
                  prefix={`conditions.${id}`}
                  id={fieldIdx}
                  type={type}
                />
              </Fragment>
            ))}
          </>
        ) : (
          <StyledAddItemButton operator={item.operator}>
            <Button
              onClick={() =>
                insertSubField(0, {
                  targetType:
                    type === 'display' ? 'question' : defaultTargetValue,
                  operator: defaultOperatorValue,
                  value: '',
                })
              }
            >
              Add Field
            </Button>
          </StyledAddItemButton>
        )}
      </StyledQuestionsWrapper>
    </StyledItemCondition>
  );
}
